import React from "react";
import { Link } from "gatsby";

class Menu extends React.Component {
    render () {
        return (
            <nav className="nav">
                <ul className="mainnav">
                    <li>
                        <Link to="/about/" className="mainnav-link font-bold text-white">About</Link>
                    </li>
                    <li>
                        <Link to="/teams/" className="mainnav-link font-bold text-white">Teams</Link>
                    </li>
                    <li>
                        <Link to="/events/" className="mainnav-link font-bold text-white">Events</Link>
                    </li>
                    <li>
                        <Link to="/new-stadium/" className="mainnav-link font-bold text-white">New Stadium</Link>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Menu;
