import React from "react";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { responsiveNav: null };
    }
    render() {
        return (
            <footer className="bg-blue-700 py-8">
                <div className="container-fluid text-center text-white">
                    Copyright © 2019. Research and logo design by Xander Utjesenovic.
                </div>
            </footer>
        );
    }
}

export default Footer;
