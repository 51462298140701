import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Logo from 'images/logos/sydney-football-stadium-logo.svg';
import Menu from "components/menu";

const responsiveNav = (typeof window !== 'undefined') ? require("js/responsive-nav.min.js") : '';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { responsiveNav: null };
    }

    componentDidMount() {
        if (!this.state.responsiveNav) {
            this.setState({
                responsiveNav: responsiveNav(".nav",{
                    customToggle: "toggle",
                })
            });
        }
    }

    render() {
        return (
            <header className="header pt-3 pb-0 md:pb-3">
                <div className="container-fluid flex flex-wrap justify-between items-center">
                    <Link
                        to="/"
                        className="headerlogo w-4/5 md:w-auto"
                    >
                        <img className="logo"
                            src={Logo}
                            alt={this.props.siteTitle + ` logo`}
                        />
                    </Link>
                    <button className="custom-toggle" id="toggle">
                        <s className="bar"></s>
                        <s className="bar"></s>
                        <s className="bar"></s>
                    </button>
                    <div className="pt-3 md:pt-0 w-full md:w-auto">
                        <Menu />
                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Website Title`,
};

export default Header;
