import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ pageData }) => {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                    color
                    lang
                }
            }
        }
    `);

    const defaults = data.site.siteMetadata;
    const title = pageData.title || defaults.title;
    const description = pageData.description || defaults.description;
    const color = pageData.color || defaults.color;
    const lang = pageData.lang || defaults.lang;

    const htmlClass = ""

    return (
        <Helmet 
            htmlAttributes={{lang}}
            bodyAttributes={{
                class: 'font-sans leading-normal text-gray-700'
            }}
        >
            <title>{title}</title>
            <meta name="robots" content="noindex,nofollow" />
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color={color} />
            <meta name="msapplication-TileColor" content={color} />
        </Helmet>
    );
};

export default SEO;
